import { OfferingIntent } from '../../../platform/navigation/navigation.const';
import {
  trackOfferingBookingClick,
  trackOfferingClickFromWidget,
} from './analytics-adapter.helper';

export const logToGoogleAnalytics = (
  intent: string,
  businessName: string,
  offering,
  isEditorMode: boolean,
  trackEventFunc: Function,
) => {
  if (intent === OfferingIntent.BOOK_OFFERING) {
    trackOfferingBookingClick(
      offering,
      businessName,
      isEditorMode,
      trackEventFunc,
    );
  } else {
    trackOfferingClickFromWidget(
      offering,
      0,
      isEditorMode,
      businessName,
      trackEventFunc,
    );
  }
};
